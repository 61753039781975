<template>
  <Chip
    class="!px-0 mx-0 border-none"
    size="md"
    :color="hasValue ? ChipColor.PRIMARY : ChipColor.ERROR"
  >
    {{
      withValue
        ? variableValue || `{ ${convertCamelToSpace(type)} ` + '}'
        : `{ ${convertCamelToSpace(type)} ` + '}'
    }}
  </Chip>
</template>

<script setup lang="ts">
import { Chip, ChipColor } from '~/components/chip';
import { computed, watch, onMounted } from '#imports';
import { useAccountStore } from '~/store/account';
import { ErrorState } from '~/store/variables-modal';
import { convertCamelToSpace } from '~/utils/text';

type Props = {
  type: string;
  withValue?: false;
};

const props = defineProps<Props>();
const emit = defineEmits<{
  (
    e: 'update:error-state',
    value: { value: boolean; variableType: string },
  ): ErrorState;
}>();

const store = useAccountStore();
const variableValue = computed(
  (): string => store.accountVariables[props.type],
);
const hasValue = computed(() =>
  Boolean(props.withValue ? variableValue.value : true),
);

watch(
  () => variableValue.value,
  (value) => {
    emit('update:error-state', {
      value: !value,
      variableType: props.type,
    });
  },
);

onMounted(() => {
  emit('update:error-state', {
    value: !hasValue.value,
    variableType: props.type,
  });
});
</script>
